import React from 'react'

const HeaderFatigaNeuroMuscular = ({ data }) => {

    return <div>

        <table className="table">
            <thead>
                <tr>
                    <th>Altura</th>
                    <th>F. Relativa</th>
                    <th>Fuerza</th>
                    <th>Potencia</th>
                    <th>P. Relativa</th>
                    <th>Tipo Salto</th>
                    <th>Velocidad</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{data.altura}</td>
                    <td>{data.frelativa}</td>
                    <td>{data.fuerza}</td>
                    <td>{data.potencia}</td>
                    <td>{data.prelativa}</td>
                    <td>{data.tipo_salto}</td>
                    <td>{data.velocidad}</td>
                </tr>
            </tbody>
        </table>


        


    </div>


}


export default HeaderFatigaNeuroMuscular