const Tests = ({ axios, env })=>({
    
    GetDates :  async ( athlete_id, macaddress, type ) => {
        try {
            let req = ''
            
            if( type === 'perfil' ){
                req = `${env.api_uri}/test/profile-dates-macaadress/${athlete_id}/${macaddress}`
                console.log(req)
            }

            if( type === 'fatiga' )
                req = `${env.api_uri}/test/fatigue-dates/${athlete_id}/${macaddress}`

            if( type === 'fatiga-entrenamiento' )
                req = `${env.api_uri}/test/fatigue-trainer-dates/${athlete_id}/${macaddress}`

            if( type === 'salto-individual' )
                req = `${env.api_uri}/test/individual-jump-dates/${athlete_id}/${macaddress}`
            
            if( type === 'pliometria' )
                req = `${env.api_uri}/test/pylometry-dates/${athlete_id}/${macaddress}`

            if( type === 'sprint' )
                req = `${env.api_uri}/test/sprint-dates/${athlete_id}/${macaddress}`

            if( type === 'lateral-deficit' )
                req = `${env.api_uri}/test/lateral-deficit-dates/${athlete_id}/${macaddress}`

            const {data} = await axios.get(req)
            return data

        } catch (error) {
            console.log(error)
        }
    }
})

export default Tests