import React from 'react';
import { BrowserRouter,Switch,Route } from 'react-router-dom';
import UserProvider from './Contexts/UserProvider'
import LoginPage from './Pages/LoginPage'
import HomeDashboard from './Pages/HomeDashboard'
import Comparativas from './Pages/Comparativas'
import Individual from './Pages/Individual'
import Informes from './Pages/Informes'
import SecurityPage from './Pages/SecurityPage'
import Logout from './Pages/Logout'


function App() {
  return (
    <BrowserRouter>
		<Switch>
			<UserProvider>
						<Route exact path='/' render={(props) => <LoginPage />}/>
            <Route exact path='/dashboard' render={(props) => <HomeDashboard />}/>
            <Route exact path='/comparativas' render={(props) => <Comparativas />}/>
            <Route exact path='/Individual' render={(props) => <Individual />}/>
            <Route exact path='/informes' render={(props) => <Informes />}/>
            <Route exact path='/security' render={(props) => <SecurityPage />}/>
            <Route exact path='/login' render={(props) => <LoginPage />}/>
            <Route exact path='/logout' render={(props)=> <Logout />} />
		</UserProvider>
		</Switch>
	</BrowserRouter>
  );
}

export default App;
