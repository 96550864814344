import React, { useContext, useEffect } from "react";
import LoaderPage from "./LoaderPage";
import UserContext from "../Contexts/UserContext";
import Page from "../Components/Page/Index";
import Menu from "../Components/Menu";
import TopBar from "../Components/TopBar/Index";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import PruebaExplorer from '../Components/PruebaExplorer/Index'

function Index(props) {
  const user = useContext(UserContext);

  useEffect(() => {
    var body = document.body;
    body.classList.add("app");
    body.classList.remove("login");
  }, []);

  if (!user.access_token) {
    return <LoaderPage />;
  }

  return (
    <Page>
      <Menu active="informes" />
      <div className="content">
        
        <TopBar />
        
        <Tabs>
          <TabList>
            <Tab>Salto Individual</Tab>
            <Tab>Perfil</Tab>
            <Tab>Pliometria</Tab>
            <Tab>Fatiga Neuro Muscular</Tab>
            <Tab>Deficit Lateral</Tab>
            <Tab>Sprint</Tab>
            <Tab>Fatiga Entrenamiento</Tab>
          </TabList>

          <TabPanel><PruebaExplorer type="salto-individual" report /></TabPanel>
          <TabPanel><PruebaExplorer type='perfil' report /></TabPanel>
          <TabPanel><PruebaExplorer type='pliometria' report /></TabPanel>
          <TabPanel><PruebaExplorer type="fatiga" report /></TabPanel>
          <TabPanel><PruebaExplorer type='lateral-deficit'  report /></TabPanel>
          <TabPanel><PruebaExplorer type='sprint'  report /></TabPanel>
          <TabPanel><PruebaExplorer type="fatiga-entrenamiento" report /></TabPanel>

        </Tabs>
      </div>
    </Page>
  );
}

export default Index;
