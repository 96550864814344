import React from 'react'
import BadgeList from '../BadgeList'

const HeaderFatigaNeuroMuscular = ({ data }) => {

    return <div style={{paddingTop : 10}}>

        <table className="table">
            <thead>
                <tr>
                    <th>Altura vuelo</th>
                    <th>Alturas</th>
                    <th>Q</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <BadgeList list={data.altura_vuelo} />
                    </td>
                    <td>
                        <BadgeList list={data.alturas} />
                    </td>
                    <td>
                        <BadgeList list={data.q} />
                    </td>
                </tr>
            </tbody>
        </table>

    </div>


}


export default HeaderFatigaNeuroMuscular