import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';

function Index({ active }){

	return (
		<nav className="side-nav">
		<Link to="" className="intro-x flex items-center pl-5 pt-4">
			<img alt="main logo" className="w-9" src="/logo.png" style={{borderRadius : '15px'}}/>
		</Link>
		<div className="side-nav__devider my-6"></div>
		<ul>
			<li>
				<Link to="/" className={`side-menu ${(active === "dashboard" ) ? 'side-menu--active' : '' }`}>
					<div className="side-menu__icon"> 
						<FeatherIcon icon="home" />
					</div>
					<div className="side-menu__title"> Dashboard </div>
				</Link>
			</li>

			<li>
				<Link to="/comparativas" className={`side-menu ${(active === "comparativas" ) ? 'side-menu--active' : '' }`}>
					<div className="side-menu__icon"> 
						<FeatherIcon icon="bar-chart-2" />
					</div>
					<div className="side-menu__title"> Comparativa </div>
				</Link>
			</li>

			{/* <li>
				<Link to="/individual" className={`side-menu ${( active === "individual" ) ? 'side-menu--active' : '' }`}>
					<div className="side-menu__icon"> 
						<FeatherIcon icon="bar-chart" />
					</div>
					<div className="side-menu__title"> Individual </div>
				</Link>
			</li> */}

			<li>
				<Link to="/informes" className={`side-menu ${( active === "informes" ) ? 'side-menu--active' : '' }`}>
					<div className="side-menu__icon"> 
						<FeatherIcon icon="file-text" />
					</div>
					<div className="side-menu__title"> Individual e Informes </div>
				</Link>
			</li>
			
			<li>
				<Link to="/logout" className={`side-menu ${(active === "logout" ) ? 'side-menu--active' : '' }`}>
					<div className="side-menu__icon">
						<FeatherIcon icon="log-out" />
						</div>
					<div className="side-menu__title"> Salir </div>
				</Link>
			</li>
		</ul>
	</nav>
	)
}


export default Index