const Devices = ({ axios, env })=>({
    
    GetDevices :  async () => {
        try {
            
            const {data} = await axios.get(`${env.api_uri}/devices-trainer`)
            return data
        } catch (error) {
            console.log(error)
        }
    }
})

export default Devices