import axios from 'axios'
import Fatiga from './services/Fatiga'
import FatigaEntrenamiento from './services/FatigaEntrenamiento'
import Atlethas from './services/Atlethas'
import SaltoIndividual from './services/SaltoIndividual'
import Perfil from './services/Perfil'
import Pliometria from './services/Pliometria'
import Sprint from './services/Sprint'
import Deficit from './services/LateralDeficit'
import Devices from './services/Devices'
import Tests from './services/Tests'
import env from '../Env'

const ServiceFatiga                 = Fatiga({ axios, env })
const ServiceFatigaEntrenamiento    = FatigaEntrenamiento({ axios, env })
const ServiceAtlethas               = Atlethas({ axios, env })
const ServiceSaltoIndividual        = SaltoIndividual({ axios, env })
const ServicePerfil                 = Perfil({ axios, env })
const ServicePliometria             = Pliometria({ axios, env })
const ServiceSprint                 = Sprint({ axios, env })
const ServiceDevices                = Devices({ axios, env })
const ServiceTests                  = Tests({ axios, env })
const ServiceDeficit                = Deficit({ axios, env })

export {
    ServiceFatiga,
    ServiceAtlethas,
    ServiceFatigaEntrenamiento,
    ServiceSaltoIndividual,
    ServicePerfil,
    ServicePliometria,
    ServiceSprint,
    ServiceDevices,
    ServiceTests,
    ServiceDeficit
}