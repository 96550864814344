import React from 'react'

const HeaderFatigaNeuroMuscular = ({ data }) => {

    return <div>
        
        {/*
            altura_pos : "31.7",
            altura_pre: "0.1",
            pos: "0.2",
            pre: "1.4"
        */}

        <table className="table">
            <thead>
                <tr>
                    <th>Altura Pos</th>
                    <th>Altura Pre</th>
                    <th>Pos</th>
                    <th>Pre</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                
                    <td>{data.altura_pos}</td>
                    <td>{data.altura_pre}</td>
                    <td>{data.pos}</td>
                    <td>{data.pre}</td>

                </tr>
            </tbody>
        </table>


        


    </div>


}


export default HeaderFatigaNeuroMuscular