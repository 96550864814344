import React,{useState, useEffect,useContext } from 'react'
import  { Bar } from '@reactchartjs/react-chart.js'
import UserContext from '../../Contexts/UserContext'
import Env from '../../Env'
import  { ServiceDevices, 
        ServiceAtlethas, 
        ServiceTests, 
        ServicePerfil, 
        ServiceFatiga, 
        ServiceFatigaEntrenamiento, 
        ServiceSaltoIndividual, 
        ServicePliometria, 
        ServiceSprint,
        ServiceDeficit
    } from '../../infrastructure/index'
import  { GraphData, GraphOptions , SetBackgroundColor, SetBorderColor } from '../../DataPerfil'
import { randomRgba } from '../../helpers'
import Select from 'react-select'

import HeaderFatigaNeuroMuscular from '../HeaderFatigaNeuroMuscular'
import HeaderFatigaEntrenamiento from '../HeaderFatigaEntrenamiento'
import HeaderSaltoIndividual from '../HeaderSaltoIndividual'
import HeaderPerfil from '../HeaderPerfil'
import HeaderPliometria from '../HeaderPliometria'
import HeaderSprint from '../HeaderSprint'
import HeaderLateralDeficit from '../HeaderLateralDeficit'



const Index = ({type, report}) => {
    
    const bg = ['#B1C27A', "#51C0BF" , "#B2E289", "#7095E1", "#C993D4", "#8DB6C7", "#9FA3E3", "#59ADD0"]

    const user = useContext(UserContext);

    const [ macAddressOptions ,setMacAddressOptions ] = useState([])
    const [ atlethasOptions ,setAtlethasOptions ] = useState([])
    const [ datesOptions ,setDatesOptions ] = useState([])
    const [ macaddress, setMacaddress ] = useState('')
    const [ atletha, setAtletha ] = useState('')
    const [ selectedDate, setSelectedDate ] = useState('')
    const [ grafica, setGrafica ] = useState([])
    const [observation, setObservation] = useState('')



    const Template = () => {

        const graph   = { }
        console.log('@@@@@')
        console.log(GraphData)
        console.log('@@@@@')

        if(type === 'fatiga' && grafica.length > 0){
            
            graph.labels = [ "ALTURA", 'Q' ]
            graph.datasets = [{
                label: `(${grafica[0].user.name} ${grafica[0].user.identification_card})` ,
                data: [
                grafica[0].test.alturas.reduce(( a, b ) => Number(a) + Number(b), 0) / grafica[0].test.alturas.length
                ,grafica[0].test.q.reduce(( a, b ) => Number(a) + Number(b), 0) / grafica[0].test.alturas.length
                ],
                backgroundColor:  bg,
                borderColor:      [ ...SetBorderColor( 'red' ), 1 ],
                borderWidth: 1
            }]
        }


            if(type === 'fatiga-entrenamiento' && grafica.length > 0){

                graph.labels = [ "ALTURA POS", 'ALTURA PRE' ]
                graph.datasets = [{
                    label: `(${grafica[0].user.name} ${grafica[0].user.identification_card})` ,
                    data: [
                        grafica[0].test.altura_pos,
                        grafica[0].test.altura_pre
                    ],
                    backgroundColor:  bg,
                    borderColor:      [ ...SetBorderColor( 'red' ), 1 ],
                    borderWidth: 1
                }]
    
                }


            if(type === 'perfil' && grafica.length > 0){

                graph.labels = ["SJ", "CMJ", "CONTINUO", "CONTINUO-Q"]
                graph.datasets = [{
                        label: `(${grafica[0].user.name} ${grafica[0].user.identification_card})` ,
                        data: [ 
                            grafica[0].test.sj.altura,
                            grafica[0].test.cmj.altura,
                            grafica[0].test.continuo.altura,
                            grafica[0].test.continuo.q
                        ],
                        backgroundColor:  bg,
                        borderColor:      [ 'black', 'black ','black ','black ','black ','black', 'black','black','black','black','black','black','black','black','black','black','black','black','black','black'],
                        borderWidth: [1,1,1,1]
                        }]
                }



                if( type === 'pliometria' && grafica.length > 0 ){
                    
                    // graphType = 'line'
                    // graph.labels = grafica[0].test.alturas // alturas
                    graph.labels = ['Altura', 'Q']
                    graph.datasets = [{
                        label: `(${grafica[0].user.name} ${grafica[0].user.identification_card})` ,
                        data:
                        [ 
                            grafica[0].test.alturas.reduce(( a, b ) => Number(a) + Number(b), 0) / grafica[0].test.alturas.length
                                ,
                            grafica[0].test.q.reduce(( a, b ) => Number(a).toF + Number(b), 0) / grafica[0].test.alturas.length
                        ],
                        backgroundColor:  bg,
                        borderColor:      [ ...SetBorderColor( 'red' ), 1 ],
                        borderWidth: 1
                    }]


                    // graphType = 'line'
                    // graph.labels = grafica[0].test.alturas // alturas
                    // graph.datasets = [{
                    //     label: grafica[0].user.identification_card,
                    //     data: grafica[0].test.altura_vuelo,
                    //     //[ 
                    //         // alturas vuelos
                    //             // 25, 28, 29, 30
                    //         // grafica[0].test.alturas.reduce(( a, b ) => Number(a) + Number(b), 0) / grafica[0].test.alturas.length
                    //             // ,
                    //         // grafica[0].test.q.reduce(( a, b ) => Number(a) + Number(b), 0) / grafica[0].test.alturas.length
                    //     //],
                    //     // backgroundColor:  SetBackgroundColor(randomRgba(),3),
                    //     borderColor:      [ ...SetBorderColor( 'red' ), 1 ],
                    //     borderWidth: 1
                    // }]
                }


                if(type === 'sprint' && grafica.length > 0){
                    graph.labels = [ "PROMEDIO VELOCIDAD" ]
                    graph.datasets = [{
                        label: `(${grafica[0].user.name} ${grafica[0].user.identification_card})` ,
                            data: [ 
                                grafica[0].test.promedio_v,
                            ],
                            backgroundColor:  bg,
                            borderColor:      [ ...SetBorderColor( 'red' ), 1 ],
                            borderWidth: 1
                            }]
                    }


                    if(type === 'salto-individual' && grafica.length > 0){

                        graph.labels = [ "ALTURA" ]
                        graph.datasets = [{
                            label: `(${grafica[0].user.name} ${grafica[0].user.identification_card})` ,
                                data: [ 
                                    grafica[0].test.altura
                                ],
                                backgroundColor:  bg,
                                borderColor:      [ ...SetBorderColor( 'red' ), 1 ],
                                borderWidth: 1
                                }]
                        }


                    if(type === 'lateral-deficit' && grafica.length > 0){

                        graph.labels = ['deficit_sj',
                        'deficit_cmj',
                        'deficit_continuo',

                        'left_sj',
                        'left_cmj',
                        'left_continuo',

                        'right_sj',
                        'right_cmj',
                        'right_continuo']

                        graph.datasets = [{
                                label: `(${grafica[0].user.name} ${grafica[0].user.identification_card})` ,
                                data: [ 
                                    grafica[0].test.deficit_sj,
                                    grafica[0].test.deficit_cmj,
                                    grafica[0].test.deficit_continuo,
                                    
                                    grafica[0].test.left_sj,
                                    grafica[0].test.left_cmj,
                                    grafica[0].test.left_continuo,
                                    
                                    grafica[0].test.right_sj,
                                    grafica[0].test.right_cmj,
                                    grafica[0].test.right_continuo,
                                ],
                                backgroundColor:  SetBackgroundColor(randomRgba(), 9 ),
                                borderColor:      [ ...SetBorderColor( 'red' ), 1 ],
                                borderWidth: 1
                                }]
                        }
                        
                        console.log(graph)

            return (
                <div>

                <input name='chart' value={JSON.stringify(graph)} onChange={()=>{return }} style={{display : 'none'}} />



                { 
                report && <Bar data={ graph }
                    options={ GraphOptions }
                    height={30}
                    width={100}
                    style={{height : '30px !important'}}
                    />
                }

                {/* {report && graphType === 'line' && <Line data={ graph } 
                        options={ GraphOptions }
                        width={100}
                        height={20}
                        style={{height : '20px !important'}}
                    />
                } */}

                </div>
            )

    }


    useEffect(() => {

        ServiceDevices.GetDevices().then( data  => {

            try {
                setMacAddressOptions(
                    data.map( item  => ({ label : item.macaddress, value : item.macaddress })) 
                )
            } catch (error) {
                console.log(error)
            }
            
        })


        ServiceAtlethas.GetAtlethas().then( data => {
            try {
                setAtlethasOptions(
                    data.map( item  => ({ label : item.name, value : item._id }))
                )
            } catch (error) {
                console.log(error)
            }
            
        })

    }, []);


    useEffect(() => {
        
        try {
            
            if(macaddress && atletha && type)
            ServiceTests.GetDates( atletha,macaddress, type ).then( data => {
                try {
                    setDatesOptions(
                        data.map(
                            item  => ({ label : item, value : item })
                            )
                    )
                } catch (error) {
                    console.log(error)
                }   
            })

        } catch (error) {
            
        }

    }, [ macaddress, atletha, type ]);
    
    useEffect(() => {

        if(macaddress && atletha && selectedDate){

                if( type === 'fatiga' )
                ServiceFatiga.Individual({ create_at : selectedDate, athlete_id:atletha, macaddress: macaddress }).then(data=>{
                    setGrafica( data )
                })

                if( type === 'perfil' )
                ServicePerfil.Individual({ create_at : selectedDate, athlete_id:atletha, macaddress: macaddress }).then(data=>{
                    setGrafica( data )
                })

                if( type === 'fatiga-entrenamiento' )
                ServiceFatigaEntrenamiento.Individual({ create_at : selectedDate, athlete_id:atletha, macaddress: macaddress }).then(data=>{
                    setGrafica( data )
                })

                if( type === 'salto-individual' )
                ServiceSaltoIndividual.Individual({ create_at : selectedDate, athlete_id:atletha, macaddress: macaddress }).then(data=>{
                    setGrafica( data )
                })


                if( type === 'pliometria' )
                ServicePliometria.Individual({ create_at : selectedDate, athlete_id:atletha, macaddress: macaddress }).then( data=>{
                    setGrafica( data )
                })


                if( type === 'sprint' )
                ServiceSprint.Individual({ create_at : selectedDate, athlete_id:atletha, macaddress: macaddress }).then(data=>{
                    setGrafica( data )
                })

                if( type === 'lateral-deficit' )
                ServiceDeficit.Individual({ 
                    create_at : selectedDate, 
                    athlete_id:atletha, macaddress: macaddress }).then(data=>{
                    setGrafica( data )
                })



                
        }

    }, [macaddress, atletha, selectedDate, type]);


    return (<div>

        <Select options={ macAddressOptions } onChange={e=>setMacaddress(e.value)} />

        <Select options={ atlethasOptions } onChange={e=>setAtletha(e.value)}/>

        <Select options={ datesOptions } onChange={e=>setSelectedDate(e.value)}/>
        
        { 
        <form target="_blank" action={`${Env.api_uri}/pdf`} method='POST'>
            
            <input name='athlete_id' value={ atletha || '' } style={{display : 'none'}} onChange={ ()=>console.log('') }  />

            <input name='test' value={ JSON.stringify(grafica) }  onChange={()=>console.log('')} style={{display : 'none'}}  />
            <input name='type' value={type}  style={{display : 'none'}} onChange={()=>console.log('')} />
            <input name='token' value={user.token}  style={{display : 'none'}} onChange={()=>console.log('')} />
            <input name='create_at' value={selectedDate}  style={{display : 'none'}} onChange={()=>console.log('')} />
            <textarea
            rows="5"
            style={{width : '100%'}}
            placeholder='Observaciones' 
            name='description' 
            onChange={(e)=>{setObservation(e.target.value)}} value={ observation } />
            <br/>
            <br/>
            <button type='submit' className="button text-white bg-theme-1 shadow-md mr-2">Generar reporte</button>

            { grafica.length > 0 &&
        <div>

            { type === 'fatiga' && <HeaderFatigaNeuroMuscular data={grafica[0].test} /> }

            { type === 'fatiga-entrenamiento' && <HeaderFatigaEntrenamiento data={grafica[0].test} /> }

            { type === 'salto-individual' && <HeaderSaltoIndividual data={grafica[0].test} /> }

            { type === 'perfil' && <HeaderPerfil data={grafica[0].test} /> }
            
            { type === 'pliometria' && <HeaderPliometria data={grafica[0].test} /> }

            { type === 'sprint' && <HeaderSprint data={grafica[0].test} /> }

            { type === 'lateral-deficit' && <HeaderLateralDeficit data={grafica[0].test} /> }
            
        </div>
        }


            <Template />


            

        </form>
        }
        
        
    </div>)

}

export default Index