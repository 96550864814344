

const Enviroment = 'development'

const base_url = (server, param) => {
  return `${server}/${param}`
}


let exportEnv = {}

if (Enviroment === 'development') {
  exportEnv = {
    // static_files: 'http://localhost:3000/public/',
    api_uri: 'https://api.wheelertecnologia.com:9000'
  }
}
else {
  exportEnv = {
    api_uri: '',
    static_files: ''
  }
}


const env = {
  base_url,
  ...exportEnv
}
export default env

