import React, { useContext, useEffect } from "react";
import LoaderPage from "./LoaderPage";
import UserContext from "../Contexts/UserContext";
import Page from "../Components/Page/Index";
import Menu from "../Components/Menu";
import TopBar from "../Components/TopBar/Index";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import CompareFatiga from "../Components/Pruebas/pages/CompareFatiga"
import CompareFatigaEntrenamiento from "../Components/Pruebas/pages/CompareFatigaEntrenamiento"
import CompareIndividualJump from "../Components/Pruebas/pages/CompareIndividualJump"
import ComparePerfil from "../Components/Pruebas/pages/ComparePerfil"
import ComparePliometria from "../Components/Pruebas/pages/ComparePliometria"
import CompareSprint from "../Components/Pruebas/pages/CompareSprint"
import CompareDeficit from "../Components/Pruebas/pages/CompareDeficit"

function Index(props) {
  const user = useContext(UserContext);


  useEffect(() => {
    var body = document.body;
    body.classList.add("app");
    body.classList.remove("login");
  }, []);

  if (!user.access_token) {
    return <LoaderPage />;
  }

  return (
    <Page>
      <Menu active="comparativas" />
      <div className="content">
      <TopBar />

    
      <div className="grid grid-cols-12 gap-6">
    <div className="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6">

    <div className="col-span-12 mt-8">
    <Tabs>
          <TabList>
            <Tab>Salto Individual</Tab>
            <Tab>Perfil</Tab>
            <Tab>Pliometria</Tab>
            <Tab>Fatiga Neuro Muscular</Tab>
            <Tab>Déficit Lateral</Tab>
            <Tab>Sprint</Tab>
            <Tab>Fatiga Entrenamiento</Tab>
          </TabList>
          
          <TabPanel><CompareIndividualJump /></TabPanel>
          <TabPanel><ComparePerfil /></TabPanel>
          <TabPanel><ComparePliometria /></TabPanel>
          <TabPanel><CompareFatiga /></TabPanel>
          <TabPanel><CompareDeficit /></TabPanel>
          <TabPanel><CompareSprint /></TabPanel>
          <TabPanel><CompareFatigaEntrenamiento /></TabPanel>
          
        </Tabs>
    </div>

      </div>
      </div>
      </div>
    </Page>

  );
}

export default Index;
