const Atlethas = ({ axios, env })=>({

    GetAtlethas : async (body) => {
        try {
            const {data} = await axios.get(`${env.api_uri}/athleta` , body )
            return data
        } catch (error) {
            console.log(error)   
        }
    }
    
})


export default Atlethas