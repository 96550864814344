import React from 'react'
import BadgeList from '../BadgeList'

const HeaderFatigaNeuroMuscular = ({ data }) => {

    return <div>
        
        <table className="table">
            <thead>
                <tr>
                    <th>Alturas</th>
                    <th>Desviacion vuelo</th>
                    <th>Promedio Q</th>
                    <th>Promedio vuelos</th>
                    <th>Q</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <BadgeList list={data.alturas} />
                    </td>
                    <td>
                        {data.desviacion_vuelo}
                    </td>
                    <td>
                        {data.promedio_q}
                    </td>
                    <td>
                        {data.promedio_vuelos}
                    </td>
                    <td>
                        <BadgeList list={data.q} />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}


export default HeaderFatigaNeuroMuscular