
const Fatiga = ({axios, env})=>({

    Individual : async ( body ) => {
        try {
            const { data } = await axios.post( `${env.api_uri}/test/fatigue-stats-mac`, body )
            return data
        } catch (error) {
            console.log(error)
        }
    },
    Stats : async (body) => {
        try {
            const { data } = await axios.post( `${env.api_uri}/test/fatigue-stats`, body )
            return data
        } catch (error) {
            console.log(error)
        }
    },
    Dates : async (_id)=>{
        try {
            const { data } = await axios.get(`${env.api_uri}/test/fatigue-dates/${_id}`)
            return data
        }catch(error){
            console.log(error)
        }
    }
    

})


export default Fatiga