import React, { useContext, useEffect } from "react";
import LoaderPage from "./LoaderPage";
import UserContext from "../Contexts/UserContext";
import Page from "../Components/Page/Index";
import Menu from "../Components/Menu";
import TopBar from "../Components/TopBar/Index";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import PruebaExplorer from '../Components/PruebaExplorer/Index'

function Index(props) {
  const user = useContext(UserContext);

  useEffect(() => {
    var body = document.body;
    body.classList.add("app");
    body.classList.remove("login");
  }, []);

  if (!user.access_token) {
    return <LoaderPage />;
  }

  return (
    <Page>
      <Menu active="individual" />
      <div className="content">
        <TopBar />

        <Tabs>
          <TabList>
            <Tab>Salto Individual</Tab>
            <Tab>Perfil</Tab>
            <Tab>Pliometria</Tab>
            <Tab>Fatiga Neuro Muscular</Tab>
            <Tab>Deficit Lateral</Tab>
            <Tab>Sprint</Tab>
            <Tab>Fatiga Entrenamiento</Tab>
          </TabList>

          <TabPanel><PruebaExplorer type="salto-individual" /></TabPanel>
          <TabPanel><PruebaExplorer type='perfil' /></TabPanel>
          <TabPanel><PruebaExplorer type='pliometria' /></TabPanel>
          <TabPanel><PruebaExplorer type="fatiga" /></TabPanel>
          <TabPanel><PruebaExplorer type='lateral-deficit' /></TabPanel>
          <TabPanel><PruebaExplorer type='sprint' /></TabPanel>
          <TabPanel><PruebaExplorer type="fatiga-entrenamiento" /></TabPanel>
          
        </Tabs>
      </div>
    </Page>
  );
}

export default Index;
