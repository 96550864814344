

const SetBackgroundColor = ( color, qty ) => {
  let result = []
  for( let i=0; i <= qty; i++ ){
    result.push(color)  
  }
  return result
}

const SetBorderColor = ( color, qty ) => {
  let result = []
  for( let i=0; i < qty - 1; i++ ){
    result.push(color)  
  }
  return result
}

const GraphOptions = {
  
  title: {
    display: false,
    text: 'Prueba de perfil'
  },
  tooltips: {
    mode: 'index',
    intersect: false
  },
  responsive: true,
  scales: {
    xAxes: [{
      stacked: false,
    }],
    yAxes: [{
      stacked: false,
      ticks: {
        beginAtZero: true
    }
    }],
  }
}


const GraphData = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor:  [],
        borderColor:      [],
        borderWidth: 1
      }
    ]
  
  }
  
  
  export { GraphData, GraphOptions, SetBackgroundColor, SetBorderColor }