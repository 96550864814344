import React, {useContext, useEffect} from 'react'
import axios from 'axios'
import { Redirect } from 'react-router'
// import { Link } from 'react-router-dom'
import { store } from 'react-notifications-component'

import UserContext from '../Contexts/UserContext'
import Env from '../Env'


function Index( props ){

  const [ email, setEmail ]         =   React.useState('')
  const [ password, setPassword ]   =   React.useState('')
  const user                        =   useContext(UserContext)
  const { api_uri }       =   Env

  useEffect( () => {
    var body = document.body;
    body.classList.add("login");
  },[ ])

  const onChangeEmail  = ( e )=>{
    setEmail(e.target.value.toLowerCase())
  }

  const onChangePassword  = ( e )=>{    
    setPassword(e.target.value)
  }


  const onSubmitHandler = async ( e ) => {

    e.preventDefault()
    if( !email || !password){
      return false
    }

    try {

       const { data } = await login({ email, password })

       if( data ){

        store.addNotification({
          title: "Bienvenido!",
          message: `${data.name}`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
  
        axios.defaults.headers.common['access-token'] = data.token
         localStorage.setItem( 'user' , JSON.stringify({
           access_token : data.token,
           ...data,
           toggleMenu : false
         }))
         
         
      }

    } catch (e) {
      

      if( e && e.response && e.response.status === 400){
        store.addNotification({
          title: "Acceso no permitido!",
          message: `Usuario o clave invalida, verifica tu informacion`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    }
    
   

    
  }

  const login = async ( data ) =>  await axios.post(`${api_uri}/auth`,data)
  
  if(user.access_token)
    return <Redirect to={'/dashboard'}/>


  return (
    <div className="container sm:px-10">
            <div className="block xl:grid grid-cols-2 gap-4">

                <div className="hidden xl:flex flex-col min-h-screen">
  
                    <div className="my-auto">
                        <img alt="logo-wheelertecnologia" className="-intro-x w-1/2 -mt-16" src="dist/images/illustration.svg" />
                        <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                            Pruebas fisicas
                        </div>
                        <div className="-intro-x mt-5 text-lg text-white dark:text-gray-500">Administre y genere reportes estadisticos de pruebas fisicas</div>
                    </div>
                </div>
                <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                    <div className="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">

                <center>
                          <img alt="logo-wheelertecnologia" src="/logo-color.png" width='300'/>
                </center>
                        <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                            Entrar
                        </h2>
                        <div className="intro-x mt-2 text-gray-500 xl:hidden text-center">Inicia sesion</div>
                        <div className="intro-x mt-8">
                            <input 
                            type="text" 
                            className="intro-x login__input input input--lg border border-gray-300 block" 
                            placeholder="Email" 
                            onChange={(e)=>onChangeEmail(e)} 
                            value={email} 
                            />

                            <input 
                            type="password" 
                            className="intro-x login__input input input--lg border border-gray-300 block mt-4" 
                            placeholder="Password" 
                            onChange={(e)=>onChangePassword(e)} 
                            value={password} 
                            />
                        </div>
                        <div className="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
                            <div className="flex items-center mr-auto">
                                <input type="checkbox" className="input border mr-2" id="remember-me" />
                                <label className="cursor-pointer select-none" htmlFor="remember-me">Remember me</label>
                            </div>
                            {/* <a href="">Forgot Password?</a>  */}
                        </div>
                        <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                            <button
                            onClick={onSubmitHandler}
                            className="button button--lg w-full xl:w-100 text-white bg-theme-1 xl:mr-3 align-top">
                              Login
                            </button>
                            {/* <button 
                              className="button button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top">
                              Sign up
                            </button> */}
                        </div>
                        <div className="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
                            {/* By signin up, you agree to our  */}
                            <br/>
                            {/* <a className="text-theme-1 dark:text-theme-10" href="">Terms and Conditions</a> & <a className="text-theme-1 dark:text-theme-10" href="">Privacy Policy</a>  */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )


  // return (
    
  //   <div className="container-fluid">
  //   <div className="row no-gutter">
  //     <div className="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex bg-image">
  //       <div className="row">
  //         <div className="col-md-10 col-lg-10 col-xl-10 my-auto mx-auto">
  //           <div>
  //             <div className="mb-4 d-flex pt-5">
  //               <img src="https://www.timbeando.com/imagenes/iSO_LOGO_TIM.png" alt="logo" width={'400px'} />
  //               </div>
  //                 <p className="text-white" style={{fontSize : '1.6em'}} >Sistema de apuestas de alto rendimiento al alcance de todos.</p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>

  //     <div className="col-md-6 col-lg-6 col-xl-5">
  //       <div className="login d-flex align-items-center py-2">

  //         <div className="container pt-5">
  //           <div className="row">
  //             <div className="col-md-10 col-lg-10 col-xl-9 mx-auto">
  //               <div className="card-sigin">
  //                 <div className="main-signup-header">
  //                    <h2 className='text-light'>Bienvenido!</h2>
  //                   <h5 className="font-weight-semibold mb-4 text-light">Por favor inicia sesión para continuar.</h5>
  //                   <form action="#" onSubmit={onSubmitHandler}>
  //                     <div className="form-group">
  //                       <label className="text-light">Usuario</label>
  //                       <input onChange={(e)=>onChangeEmail(e)} className="form-control" type="text" value={email} placeholder=''/>
  //                     </div>
  //                     <div className="form-group">
  //                       <label className="text-light">Tu clave de acceso</label>
  //                       <input onChange={(e)=>onChangePassword(e)} className="form-control" placeholder="*******" type="password" value={password}/>
  //                     </div>
                      
  //                   {(email && password) ? <button className="btn btn-primary btn-block">Entrar</button> : <button className="btn btn-primary btn-block" disabled>Entrar</button>}
                      
                      
  //                     <div className="row row-xs">
  //                       <div className="col-sm-6">
  //                         {/* <button className="btn btn-block"><i className="fab fa-facebook-f"></i> Signup with Facebook</button> */}
  //                       </div>
  //                       <div className="col-sm-6 mg-t-10 mg-sm-t-0">
  //                         {/* <button className="btn btn-danger  btn-block"><i className="fab fa-google"></i> Signup with Google</button> */}
  //                       </div>
  //                     </div>
  //                   </form>
  //                 </div>
  //                 <div className="main-signin-footer mt-5">
  //                   {/* <p><Link to="/forgot">Olvidaste tu contraseña?</Link> </p>
  //                     <p>No tienes una cuenta? <Link to="/register">Crea una cuenta</Link>
  //                   </p> */}
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
  
  //   )
}


export default Index