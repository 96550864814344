import React from 'react'

const HeaderFatigaNeuroMuscular = ({ data }) => {

    return <div style={{paddingTop : 10}}>

    <h3 style={{margin : 0, padding : 0}}>SJ</h3>
        <table className="table">
            <thead>
                <tr>
                    <th>Altura [m]</th>
                    <th>F. Relativa</th>
                    <th>Fuerza [N]</th>
                    <th>Potencia [W]</th>
                    <th>P. Relativa</th>
                    <th>Velocidad [m/s]</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{data.sj.altura}</td>
                    <td>{data.sj.f_relativa}</td>
                    <td>{data.sj.fuerza}</td>
                    <td>{data.sj.potencia}</td>
                    <td>{data.sj.prelativa}</td>
                    <td>{data.sj.velocidad}</td>
                </tr>
            </tbody>
        </table>


        <h3>CMJ</h3>
        <table className="table">
            <thead>
                <tr>
                    <th>Altura [m]</th>
                    <th>F. Relativa</th>
                    <th>Fuerza [N]</th>
                    <th>Potencia[W]</th>
                    <th>P. Relativa</th>
                    <th>Velocidad [m/s]</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{data.cmj.altura}</td>
                    <td>{data.cmj.frelativa}</td>
                    <td>{data.cmj.fuerza}</td>
                    <td>{data.cmj.prelativa}</td>
                    <td>{data.cmj.potencia}</td>
                    <td>{data.cmj.velocidad}</td>
                </tr>
            </tbody>
        </table>


        <h3>CONTINUO</h3>
        <table className="table">
            <thead>
                <tr>
                    <th>Altura [m]</th>
                    <th>F. Relativa</th>
                    <th>Fuerza [N]</th>
                    <th>Potencia [W]</th>
                    <th>P. Relativa</th>
                    <th>Velocidad [m/s]</th>
                    <th>Q</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{data.continuo.altura}</td>
                    <td>{data.continuo.frelativa}</td>
                    <td>{data.continuo.fuerza}</td>
                    <td>{data.continuo.potencia}</td>
                    <td>{data.continuo.prelativa}</td>
                    <td>{data.continuo.velocidad}</td>
                    <td>{data.continuo.q}</td>
                </tr>
            </tbody>
        </table>

    </div>


}


export default HeaderFatigaNeuroMuscular