import React from 'react';
import BadgeList from '../BadgeList';

const HeaderSprint = ({ data }) => {

    return <div>

        <table className="table">
            <thead>
                <tr>
                    <th>Desviacion</th>
                    <th>Distancias</th>
                    <th>Promedio_v</th>
                    <th>Velocidad</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{data.desviacion_v}</td>
                    <td><BadgeList list={data.distancias}/> </td>
                    <td>{data.promedio_v}</td>
                    <td><BadgeList list={data.velocidad}/> </td>
                </tr>
            </tbody>
        </table>

    </div>

}

export default HeaderSprint