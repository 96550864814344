import React, { useState, useContext, useEffect } from 'react'
import LoaderPage from './LoaderPage'
import UserContext from '../Contexts/UserContext'
import Page from '../Components/Page/Index'

import Menu from '../Components/Menu'
// import TopBar from '../Components/TopBar/Index'
// import Stats from '../Components/Stats/Index'

function Index( props ){

	const user = useContext(UserContext);
	const [ loading  ] = useState( false )

	useEffect(() => {
		var body = document.body;
		body.classList.add("app");
		body.classList.remove("login");
	},[])

	
	!user.access_token && <LoaderPage/>

	loading && <LoaderPage/>

	return (
			<Page>
				<Menu active="dashboard" />
				<div className='content'>
					{/* <TopBar /> */}
					{/* 
						<Stats/> 
					*/}

					<div className='section'>
						<h1 className='title'>SALTOS SIMPLES</h1>
						<p>En la población deportiva masculina, mayor de 16 años, podemos valorar la altura del salto con la siguiente tabla</p>
						{`Para SJ:`} <br/>
						{'<29 Deficiente'} <br/>
						{"30<x<35 "} <br/>
						{"Bueno>36 Sobresaliente"} <br/>
						{"Para cualquier otro salto:"} <br/>
						{"<34 Deficiente"} <br/>
						{"35<x<45 Bueno"} <br/>
						{">46 Sobresaliente"} <br/>
					</div>


					<div className='section'>
						<h1 className='title'>PERFIL NEUROMUSCULAR</h1>
						<p>Este test estima el estado actual de las 3 manifestaciones de la fuerza: activa (componente contráctil), elástico explosiva (tejido conectivo) y reactiva (mecanismos reflejos). Estos 3 componentes están asociados a la capacidad de acelerar, máxima velocidad y desaceleración. Está compuesto por SQ, CMJ y saltos continuos en 10 segundos. Se espera observar:</p>
						
						{'Perfil balanceado: '}
						{'El CMJ debe ser un 10% mayor al SJ y los saltos continuos deben ser iguales o superiores al SJ. '}
						{'Ejemplo: '}
						{'SJ: 25'}
						{'CMJ: (25*1,1) 28'}
						{'Saltos continuos: 26'}



						{'Perfil por mejorar deficiente en fuerza activa:'}
						{'La relación del CMJ con respecto al SJ será mayor al 10%. En este caso, el atleta tendrá que mejorar los niveles de fuerza concéntrica a través del entrenamiento con sobrecarga (pesas). Con este perfil está disminuida capacidad de aceleración (0 a 20 metros). '}
						{'Ejemplo:'}
						{'SJ: 19'}
						{'CMJ: 27'}
						{'Saltos continuos: 20'}

						{'Perfil por mejorar deficiente en el componente elástico muscular:'}
						{'El CMJ es inferior al SJ. En este caso, el atleta tendrá que entrenar el ciclo de estiramiento-acortamiento (CEA) a través de metodologías con sobre carga excéntrica, por ejemplo, pliometría, máquinas inerciales, entrenamiento excéntrico y derivados del levantamiento olímpico. Con este perfil está disminuida la capacidad de desaceleración generando mayor carga mecánica a nivel muscular y articular.'}
						{'Ejemplo:'}
						{'SJ: 27'}
						{'CMJ: 19'}
						{'Saltos continuos: 20'}

						{'Perfil por mejorar deficiente en el componente reactivo:'}
						{'Los saltos continuos son inferiores al SJ y al CMJ. En este caso, el atleta tendrá que entrenar el ciclo de estiramiento-acortamiento (CEA) reactivo a través del entrenamiento pliométrico y método pliométrico. Con este perfil está disminuida la capacidad velocidad máxima, agilidad, frenos y cambios de dirección.'}
						{'Ejemplo:'}
						{'SJ: 25'}
						{'CMJ: 28'}
						{'Saltos continuos: 15'}

					</div>

					<div className='section'>
						<h1 className='title'>DEFICIT LATERAL</h1>
						<p>Este test estima la diferencia entre pierna derecha y pierna izquierda en los 3 componentes de fuerza, pasiva, elástico explosiva y reactiva.</p>
						{'Resultados ideales:'}
						{'Para los saltos SJ y CMJ, la diferencia entre cada pierna no debe superar el 15%. '}
						{'Para los saltos continuos, la diferencia entre cada pierna no debe superar el 10%. '}
						{'El aplicativo arrojará la gráfica.'}
					</div>


					<div className='section'>
						<h1 className='title'>PLIOMETRÍA</h1>
						<p>En este aplicativo se diferencian la altura de la valla para el entrenamiento pliométrico y la altura de caída para el método pliométrico.</p>
						{'Altura de la valla: si se desea prescribir la altura de la valla para el entrenamiento, se deberá elegir "Altura de la valla". (El aplicativo arrojará la gráfica).'}
						{'Altura de caída: si se desea entrenar el método pliométrico, se deberá elegir "Altura de caída" y testear al deportista dejándolo caer desde diferentes alturas de forma progresiva (mínimo 4), observando desde que altura presenta el mejor índice Q y la mejor altura de rebote. Esta será la altura donde se manifieste la mejor capacidad reactiva del sistema neuromuscular, disminuyendo el impacto articular del aterrizaje (el aplicativo arrojará la gráfica).'}
						{'Para los saltos continuos, la diferencia entre cada pierna no debe superar el 10%. '}
						{'El aplicativo arrojará la gráfica.'}
					</div>


					<div className='section'>
						<h1 className='title'>FATIGA NEUROMUSCULAR</h1>
						<p>En este aplicativo se diferencian la fatiga neuromuscular y la fatiga de la sesión de entrenamiento. Es importante controlar la fatiga neuromuscular y la fatiga de la sesión de entrenamiento para poder tomar decisiones a tiempo y evitar sobrecargas musculares en el deportista. </p>
						{'Fatiga neuromuscular: la gráfica mostrará una caída progresiva en cada uno de los saltos. Esta situación se estaría presentando por una disminución de las capacidades reactiva y elástica muscular con lo que se estaría generando una sobrecarga en el elemento contráctil o muscular. '}
						{'Fatiga de la sesión: se tomará el salto CMJ o ABK antes de iniciar la sesión de entrenamiento y una vez finalizada la misma. Una pérdida del salto en la altura, fuerza, potencia o velocidad, mayor a un 10%, será un indicador de alta fatiga neuromuscular generada en la sesión de entrenamiento. Altura de caída: si se desea entrenar el método pliométrico, se deberá elegir "Altura de caída" y testear al deportista dejándolo caer desde diferentes alturas de forma progresiva (mínimo 4), observando desde que altura presenta el mejor índice Q y la mejor altura de rebote. Esta será la altura donde se manifieste la mejor capacidad reactiva del sistema neuromuscular, disminuyendo el impacto articular del aterrizaje (el aplicativo arrojará la gráfica).'}
					</div>


					<div className='section'>
						<h1 className='title'>VELOCIDAD</h1>
						<p>En este aplicativo se diferencian la velocidad con cambio de dirección y la capacidad de repetir Sprint con cambio de dirección.</p>
						<p style={{padding :'5px'}}>
							<b>Velocidad con cambio de dirección:</b>
							en este test, el deportista debe iniciar con uno de los dos pies haciendo contacto con los sensores 
							de WHEELERJUMP. En el aplicativo se debe configurar la distancia de desplazamiento, únicamente del 
							recorrido de ida, más no, el de ida y regreso. Por ejemplo: si va a recorrer 40 metros, 20 de ida y 20 de regreso, 
							en el aplicado se deben configurar 20 metros. 
							<br/>
							
							Cuando el deportista regresa, se deberá hacer contacto de nuevo con los sensores de WHEELERJUMP para que el 
							aplicativo arroje la velocidad de desplazamiento.
						</p>
						<p>
							<b>Capacidad de repetir Sprint con cambio de dirección:</b> 
							se deberá configurar la distancia de igual manera que en la función anterior 
							y el tiempo de pausa entre cada sprint. 
							Recomendaciones: para determinar la capacidad de repetir Sprint, 
							se recomiendan 15 repeticiones sobre una distancia de 40 metros con 15 segundos de pausa entre 
							cada repetición. Para determinar la capacidad que tiene el deportista de resintetizar de forma 
							intermitente el pull de fosfocreatina y tolerar la acidosis metabólica 
							(las velocidades promedio que calificarían una adecuada capacidad de repetir 
							Sprint serán de 4 a 5,5 metros por segundo).
						</p>
					</div>


					
					
					
					
					

				</div>
			</Page>
	)
}


export default Index