import React, {useState, useEffect, useCallback} from 'react'
import Select from 'react-select';
import  { Bar } from '@reactchartjs/react-chart.js';
import  { GraphData, GraphOptions , SetBackgroundColor, SetBorderColor } from '../../../DataPerfil'

import { ServicePliometria, ServiceAtlethas } from '../../../infrastructure'


function random_rgba() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}


function Index() {


  const [atlethas, setAtlehas] = useState([])
  const [atlethasForm, setAtlethasForm ] = useState([])
  const [ dataProfile , setDataProfile ] = useState( GraphData )

  const GetAtlethas = useCallback( async()=>{
    
        const data  = await ServiceAtlethas.GetAtlethas()

        let temp_data = []        
        data.map(item => temp_data.push({ label : item.name, value : item._id }))
        setAtlehas(temp_data)
  },[])

useEffect( () => {

  GetAtlethas()

},[ GetAtlethas ])



const GetDates = React.useCallback( async ( _id ) => {

      const data = await ServicePliometria.Dates( _id )
      return data

  },[])


  const UpdateSelectedUsers = async (e) => {
    if(e === null){

      setAtlethasForm([])
      return
    }

    let temp_form = []

    await Promise.all(e.map( async item => {
      
        let result  =   await GetDates( item.value )
        let dates   =   result.map((item)=>({ label : item , value : item }))
        let date    =   ''

        atlethasForm.map((i, k) => {



          if(i._id === item.value ){

            date = i.date

          }

          return true
        })

        temp_form.push({
          label   : item.label,
          _id     : item.value,
          date,
          options : dates,
        })
  
    }))
    

      setAtlethasForm( temp_form )

  }

  const SetParams = ({ date , _id  }) => {

    const oldState = [...atlethasForm]
    atlethasForm.map((item, key) => {

      if( item._id === _id ){

        oldState[ key ].date = date
      }
      
      return true

    })


    setAtlethasForm(oldState)


  }

  useEffect(()=>{
      
    let test = []
    atlethasForm.map( item => {
        if( item.date )
          test.push({ date : item.date , athlete_id : item._id })
        return true
    })

    const GetTests = async (body) => {

        const data = await ServicePliometria.Stats(body)
        
        let datasets  = []
        const labels  = [ "ALTURAS", "Q" ]
        const graph   = { ...GraphData }

        graph.labels = labels

        data.map(( item ) => {

          return datasets.push(
            {
              label: `(${item.user.name} ${item.user.identification_card})` ,
              data: [
                item.test.alturas.reduce(( a, b ) => Number(a) + Number(b), 0) / item.test.alturas.length
                ,
                item.test.q.reduce(( a, b ) => Number(a) + Number(b), 0) / item.test.alturas.length
                
              ],
              backgroundColor:  SetBackgroundColor(random_rgba(),2),
              borderColor:      [ ...SetBorderColor( 'red' ), 1 ],
              borderWidth: 1
            }
          )
        })

        graph.datasets = datasets 

        setDataProfile( graph )


    }

    GetTests({test})


  },[ atlethasForm ])




  return (
    <div>


        <h1 style={{textAlign : 'center'}}>PLIOMETRIA</h1>
        <label>Deportistas</label>        
          <Select
              onChange={ e => UpdateSelectedUsers(e) }
              options={atlethas}
              isMulti
          />

          {atlethasForm.map(( item, key ) => {

            return (<div key={`dinamicInput_${key}`}>
              <label>{ item.label }</label>
              <br/>
              <Select
                onChange={ e => SetParams({ date : e.value, _id : item._id  }) }
                options={item.options}
              />
              <br/>
              </div>)
            })
            }
              <Bar data={ dataProfile } 
                options={ GraphOptions }
                width={100}
                height={20}
                style={{height : '20px !important'}}
              />      
            


    </div>
  );
}

export default Index;
