import React from 'react'

const HeaderLateralDeficit = ({ data }) => {


    return (
        <div>

        <h3 style={{margin : 0, padding : 0}}>SJ</h3>
        <table className="table">
            <thead>
                <tr>
                    <th>Deficit</th>
                    <th>Left</th>
                    <th>Right</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{data.deficit_sj}</td>
                    <td>{data.left_sj}</td>
                    <td>{data.right_sj}</td>
                </tr>
            </tbody>
        </table>


        <h3 style={{margin : 0, padding : 0}}>CMJ</h3>
        <table className="table">
            <thead>
                <tr>
                    <th>Deficit</th>
                    <th>Left</th>
                    <th>Right</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{data.deficit_cmj}</td>
                    <td>{data.left_cmj}</td>
                    <td>{data.right_cmj}</td>
                </tr>
            </tbody>
        </table>


        <h3 style={{margin : 0, padding : 0}}>CONTINUO</h3>
        <table className="table">
            <thead>
                <tr>
                    <th>Deficit</th>
                    <th>Left</th>
                    <th>Right</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{data.deficit_continuo}</td>
                    <td>{data.left_continuo}</td>
                    <td>{data.right_continuo}</td>
                </tr>
            </tbody>
        </table>

        </div>
    )
    
}


export default HeaderLateralDeficit