import React from 'react'


function Index( props ){
  

  const { children } = props   
    return ( 
      <div className="flex">
        { children }
      </div>
    )

  }
   
  

  export default Index

